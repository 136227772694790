import {Component, OnDestroy, OnInit, ViewChild, AfterViewInit, Renderer2, TemplateRef, ElementRef} from '@angular/core';
import {NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NbMenuItem} from '@nebular/theme';

import {UserData} from '../../../@core/data/users';
import {LayoutService} from '../../../@core/utils';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

import {Api} from '../../../api/apiModule';
import {Router} from '@angular/router';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('popoverAnchor') popoverButton: ElementRef;
  @ViewChild('settingsRef') settings: TemplateRef<any>;
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  insUserItem = {
    title: 'Profile',
    icon: 'person-outline',
    link: 'profile',
  };

  adminUserItem = {
    title: 'Settings',
    icon: 'settings-2-outline',
    link: 'settings',
  };

  defaultMenuItem: NbMenuItem = {
    title: 'Logout',
    icon: 'unlock-outline',
    queryParams: {logout: true},
  };

  items: NbMenuItem[] = [];

  currentTheme = 'default';

  userMenu = [{title: 'Profile'}, {title: 'Log out'}];

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private renderer: Renderer2,
    private router: Router,
    private api: Api) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.items = this.api.isAdmin() ?
      [this.adminUserItem, this.defaultMenuItem] : [this.defaultMenuItem];

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const {xl} = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({name}) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngAfterViewInit(): void {
    // this.renderer.listen(this.popoverButton.nativeElement, 'click', (e: any) => {this.menuListen(e);});
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  action(e: any): void {
    const {srcElement: {innerText}} = e;
    if (innerText === 'Logout') {
      this.logout();
    }
  }

  menuListen(e: any): void {
    console.log(e);
    if (this.settings) {
      this.renderer.listen(this.settings, 'click', (event: any) => {console.log(event);});
    }
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  logout() {
    this.api.removeLocalAuth();
    this.api.logout().subscribe((value) => {
      this.router.navigate(['/login']);
    });
  }
}
