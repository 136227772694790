import {Component, OnInit} from '@angular/core';
import {NbThemeService} from '@nebular/theme';
import {NgxSpinnerService} from 'ngx-spinner';
import {Api} from '../../api/apiModule';
import {ActivatedRoute, Router} from '@angular/router';
import {timer} from 'rxjs';


@Component({
  selector: 'ngx-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  registrationConfirmed: boolean;
  registrationDenied: boolean;
  registrationMessage: string;
  src: any;


  constructor(
    private ar: ActivatedRoute,
    private api: Api,
    private themeService: NbThemeService,
    private spinner: NgxSpinnerService,
    private router: Router) {
    // Load the page theme
    this.themeService.getJsTheme();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.ar.params.subscribe((params: any) => {
      const {code} = params;
      if (!code) {
        this.registrationMessage = 'Invalid Ragesitration Code, please use the link from the confirmation email';
        this.spinner.hide();
      } else {
        this.confirmRegistration(code);
      }
    });
  }

  confirmRegistration(code: string): void {
    this.api.confirm(code).subscribe((res: any) => {
      this.registrationMessage = res.message;
      this.registrationConfirmed = true;
      this.spinner.hide();
      timer(5000).subscribe(() => {
        this.router.navigate(['/login']);
      });
    }, (err: any) => {
      this.registrationMessage = err.error.message;
      this.spinner.hide();
    });
  }

}
