import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Api } from './apiModule';

interface Auth {
  auth: boolean;
}

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    private api: Api,
    public router: Router,
  ) {
    // Constructor
  }

  // Create CanActivate route for angular router (referencing
  // refreshAuthentication so it can be used in multiple places)
  canActivate(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Promise<boolean> {
    return this.refreshAuthentication();
  }

  /**
   * This method uses the APIClient to send a get request to /user/isAuthenticated
   * which responds with code 200 if the user is logged in, and code 302 if the user
   * is not logged in. An unauthorized user is directed to the /login screen.
   *
   * @returns { Promise<boolean> } - Return a subscription wrapped in a promise with api result.
   */
  public refreshAuthentication(): Promise<boolean> {
    // On any page change => authenticate the user and send to login if invalid
    const checkAuthentication: Observable<Auth> = this.api.isAuthenticated();
    return new Promise<boolean>((resolve) => {
      checkAuthentication.subscribe((data) => {
        if (data.auth) {
          resolve(true);
        }else {
          this.router.navigate(['/login']);
          resolve(false);
        }
      });
    });
  }


}
