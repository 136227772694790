<nb-layout>
  <nb-layout-column>
    <nb-auth-block>
      <div class="row login-container">
        <nb-card class="login-card">
          <nb-card-header>
            <div class="logo">
              <img src="../../assets/images/spark/tshirtlogoblue.png" />
              <p class="sub-title">Sign Up</p>
            </div>
          </nb-card-header>
          <nb-card-body>
            <form [formGroup]="registerForm" aria-labelledby="title">
              <div class="form-control-group">
                <label class="label" for="input-firstName">First Name:</label>
                <input
                  nbInput
                  formControlName="firstName"
                  fullWidth
                  shape="semi-round"
                  name="firstName"
                  id="input-firstName"
                  placeholder="First Name"
                  fieldSize="large"
                  autofocus
                  tabindex="1"
                />
              </div>

              <div class="form-control-group">
                <label class="label" for="input-lastName">Last Name:</label>
                <input
                  nbInput
                  formControlName="lastName"
                  fullWidth
                  shape="semi-round"
                  name="lastName"
                  id="input-lastName"
                  placeholder="Last Name"
                  fieldSize="large"
                  autofocus
                  tabindex="2"
                />
              </div>

              <div class="form-control-group" *ngIf="!emailMessage">
                <label class="label" for="input-email">Email address:</label>
                <input
                  nbInput
                  formControlName="email"
                  fullWidth
                  shape="semi-round"
                  name="email"
                  id="input-email"
                  placeholder="Email address"
                  fieldSize="large"
                  autofocus
                  tabindex="3"
                />
              </div>

              <div class="form-control-group" *ngIf="emailMessage">
                <label class="label" for="input-email">Email address:</label>
                <input
                  nbInput
                  formControlName="email"
                  fullWidth
                  shape="semi-round"
                  name="email"
                  id="input-email"
                  placeholder="Email address"
                  fieldSize="large"
                  autofocus
                  tabindex="3"
                  [nbPopover]="emailError"
                  nbPopoverPlacement="bottom"
                />
                <ng-template #emailError>
                  <nb-icon
                    icon="alert-triangle-outline"
                    status="warning"
                  ></nb-icon>
                  {{ emailMessage }}
                </ng-template>
              </div>

              <div class="form-control-group">
                <label class="label" for="input-password">Password:</label>
                <input
                  nbInput
                  formControlName="password"
                  fullWidth
                  shape="semi-round"
                  name="password"
                  type="password"
                  id="input-password"
                  placeholder="Password"
                  fieldSize="large"
                  tabindex="4"
                  [nbPopover]="passwordHelper"
                  nbPopoverPlacement="end"
                />
                <ng-template #passwordHelper>
                  <p>Password must contain:</p>
                  <ul>
                    <li>A lower case letter</li>
                    <li>An upper case letter</li>
                    <li>A number</li>
                    <li>At least 8 characters</li>
                  </ul>
                </ng-template>
              </div>

              <div class="form-control-group">
                <label class="label" for="input-confirmPassword">
                  Confirm Password:
                </label>
                <input
                  nbInput
                  formControlName="confirmPassword"
                  fullWidth
                  shape="semi-round"
                  name="confirmPassword"
                  type="password"
                  id="input-confirmPassword"
                  placeholder="Confirm Password"
                  fieldSize="large"
                  tabindex="5"
                />
              </div>

              <div class="form-control-group text-center">
                <re-captcha
                  #recaptcha
                  (resolved)="resolved($event)"
                  [siteKey]="siteKey"
                ></re-captcha>
              </div>

              <button
                nbButton
                fullWidth
                type="button"
                status="primary"
                tabindex="2"
                size="large"
                (click)="register()"
                [disabled]="!registerForm.valid"
              >
                Complete Registration
              </button>

              <section class="another-action" aria-label="Register">
                Already Registered?
                <a class="text-link" routerLink="../login">Login</a>
              </section>
            </form>
          </nb-card-body>
        </nb-card>
      </div>
    </nb-auth-block>
  </nb-layout-column>
</nb-layout>
