import {NgModule} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {SparkClass, ScheduleData, Partner, User, ClassSchedule, Club, Profile, Season} from '@types';
// import {environment} from '../../environments/environment';
import {of} from 'rxjs';
import {map, concatMap} from 'rxjs/operators';

@NgModule({})
export class Api {

  config: any = {
    // withCredentials: true,
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
  };

  // host: string = environment.apiHost;
  /* GCloud node env changes breaking server deployment...
    yaml not setting environment variables in app
    temporary fix declare api route here */
  host: string = window.location.origin.includes('localhost') ?
    'http://localhost:8080/api' : `${window.location.origin}/api`;

  user: any;
  private profile: Profile;

  classReset = new Subject<any>();
  classResetSub$ = this.classReset.asObservable();
  instReset = new Subject<any>();
  instResetSub$ = this.instReset.asObservable();
  scheduleReset = new Subject<any>();
  scheduleResetSub$ = this.scheduleReset.asObservable();
  requestSub = new Subject<any>();
  requestSub$ = this.requestSub.asObservable();
  partnerReset = new Subject<any>();
  partnerResetSub$ = this.partnerReset.asObservable();
  classNames: string[];
  constructor(
    private http: HttpClient,
  ) {
    // Constructor
  }

  /**
   * Getters
   */

  getActiveSeasons(): Observable<any> {
    return this.http.get<Array<Season>>(`${this.host}/admin/data/seasons`, this.config);
  }

  getInstructorData(): Observable<any> {
    return this.http.get<any>(`${this.host}/admin/all/instructors`, this.config);
  }

  getUnregistered(): Observable<any> {
    return this.http.get<any>(`${this.host}/admin/data/unregistered`, this.config);
  }

  getSchedule(season?: string): Observable<any> {
    let url = `${this.host}/admin/data/schedule`;
    if (season) {
      url += `/${season}`;
      return this.http.get<ScheduleData>(url, this.config);

    } else {
      return this.getActiveSeasons().pipe(
        map((seasons: Array<Season>) => seasons.find((s: Season) => !!s.default)),
        concatMap((currentSeason: Season) =>
          this.http.get<ScheduleData>(`${url}/${currentSeason.season}`, this.config)));
    }
  }

  getInstructorSchedule(): Observable<any> {
    return this.http.get<ScheduleData>(`${this.host}/admin/instructor/schedule/${this.user.userID}`, this.config);
  }

  getUnstaffedSchedule(season?: string): Observable<any> {
    let url = `${this.host}/admin/data/scheduled`;
    if (season) {
      url += `/${season}`;
    }
    return this.http.get<ScheduleData>(url, this.config);
  }

  getCalendarEvents(): Observable<any> {
    return this.http.get<any>(`${this.host}/admin/data/events`, this.config);
  }

  getInstructorEvents(): Observable<any> {
    return this.http.get<any>(`${this.host}/admin/instructor/events/${this.user.userID}`, this.config);
  }

  getRequests(): Observable<any> {
    return this.http.get<any>(`${this.host}/admin/data/requests`, this.config);
  }

  getInstructorRequests(): Observable<any> {
    return this.http.get<any>(`${this.host}/admin/instructor/requests/${this.user.userID}`, this.config);
  }

  getInstructorClasses(): Observable<any> {
    return this.http.get<any>(`${this.host}/admin/instructor/classes/${this.user.userID}`, this.config);
  }

  getInstructorClassPartners(): Observable<any> {
    return this.http.get<any>(`${this.host}/admin/instructor/classes/${this.user.userID}/partners`, this.config);
  }

  getInstructors(): Observable<any> {
    return this.http.get<any>(`${this.host}/admin/data/instructors`, this.config);
  }

  getPartners(): Observable<any> {
    return this.http.get<Partner[]>(`${this.host}/admin/data/partners`, this.config);
  }

  getPartnerFactSheets(): Observable<any> {
    return this.http.get<any>(`${this.host}/admin/data/partners/factsheets`, this.config);
  }

  getClubs(): Observable<any> {
    return this.http.get<SparkClass[]>(`${this.host}/admin/data/clubs`, this.config);
  }

  getMaterials(): Observable<any> {
    return this.http.get<any>(`${this.host}/materials`, this.config);
  }

  getCloudMaterials(): Observable<any> {
    return this.http.get<any>(`${this.host}/admin/materials`, this.config);
  }

  getFile(file: string): Observable<any> {
    return this.http.get<any>(`${this.host}/materials/${file}`, this.config);
  }

  /**
   * Setters
   */

  deleteRequest(data: any): Observable<any> {
    const url = `${this.host}/admin/dashboard/request/${data.classCode}/${data.instructorID}`;
    return this.http.post<any>(url, data, this.config);
  }

  requestClass(data: any): Observable<any> {
    return this.http.post<any>(`${this.host}/admin/dashboard/request`, data, this.config);
  }

  approveClass(data: any): Observable<any> {
    return this.http.post<any>(`${this.host}/admin/dashboard/approve`, data, this.config);
  }

  removeClass(data: ClassSchedule): Observable<any> {
    return this.http.post<any>(`${this.host}/admin/program/remove`, data, this.config);
  }

  removeClassInstructor(data: ClassSchedule): Observable<any> {
    return this.http.post<any>(`${this.host}/admin/class/instructor/remove`, data, this.config);
  }

  denyClass(data: any): Observable<any> {
    return this.http.post<any>(`${this.host}/admin/dashboard/deny`, data, this.config);
  }

  postScheduleClass(schedule: SparkClass): Observable<any> {
    return this.http.post<SparkClass>(`${this.host}/admin/new/schedule`, this.clean(schedule), this.config);
  }

  editClass(schedule: SparkClass): Observable<any> {
    return this.http.put<SparkClass>(`${this.host}/admin/edit/schedule`, this.clean(schedule), this.config);
  }

  postPartner(partner: Partner): Observable<any> {
    return this.http.post<Partner>(`${this.host}/admin/new/partner`, this.clean(partner), this.config);
  }

  editPartner(partner: Partner): Observable<any> {
    return this.http.put<Partner>(`${this.host}/admin/edit/partner`, this.clean(partner), this.config);
  }

  removePartner(partner: Partner): Observable<any> {
    return this.http.post<Partner>(`${this.host}/admin/partner/remove`, this.clean(partner), this.config);
  }

  postClub(club: Club): Observable<any> {
    return this.http.post<Club>(`${this.host}/admin/new/club`, this.clean(club), this.config);
  }

  editClub(club: Club): Observable<any> {
    return this.http.put<Club>(`${this.host}/admin/edit/club`, this.clean(club), this.config);
  }

  editProgram(club: Club): Observable<any> {
    return this.http.put<Club>(`${this.host}/admin/edit/program`, this.clean(club), this.config);
  }

  removeClub(club: Club): Observable<any> {
    return this.http.post<Club>(`${this.host}/admin/club/remove`, this.clean(club), this.config);
  }

  addInstructor(email: string): Observable<any> {
    return this.http.post<any>(`${this.host}/admin/add/instructor`, {email}, this.config);
  }

  postCsvClasses(schedule: SparkClass[]): Observable<any> {
    return this.http.post<SparkClass[]>(`${this.host}/admin/new/csv`, schedule, this.config);
  }

  setUser(usr: any): void {
    this.user = usr;
    localStorage.setItem('auth', 'true');
    localStorage.setItem('usr', JSON.stringify(usr));
  }

  removeLocalAuth(): void {
    localStorage.removeItem('auth');
    localStorage.removeItem('usr');
  }

  getUserProp(prop: string): any {
    return this.user[prop];
  }

  getUserName(): string {
    const {firstName, lastName} = this.user;
    return `${firstName} ${lastName}`;
  }

  getUser(): any {
    return this.user;
  }

  isAdmin(): boolean {
    return this.user?.type === 'Admin';
  }

  setClassNames(classNames: string[]): void {
    this.classNames = classNames;
  }

  getClassNames(): Observable<any> {
    if (this.classNames) {
      return of(this.classNames);
    }
    this.getInstructorSchedule().subscribe((res: any) => {
      return of(res.body.map((s: any) => (s.className)));
    }, err => {
      return of([]);
    });
  }

  /*
   * Handle Auth routes
   */
  isAuthenticated(): Observable<any> {
    const auth = localStorage.getItem('auth');
    const storedUser = JSON.parse(localStorage.getItem('usr'));
    const usr = this.user || storedUser;
    if (usr && !this.user) {
      this.user = usr;
    }
    return of({auth: this.user ? true : !!auth, user: this.user || usr});
    // return this.http.get<any>(`${this.host}/auth`, this.config);
  }

  logout(): Observable<any> {
    return this.http.get<any>(`${this.host}/auth/logout`, this.config);
  }

  login(email: string, password: string): Observable<any> {
    const data: any = {email: email, password: password};
    return this.http.post<any>(`${this.host}/auth/login`, data, this.config);
  }

  register(user: User): Observable<any> {
    return this.http.post<User>(`${this.host}/admin/register`, this.clean(user), this.config);
  }

  confirm(code: string): Observable<any> {
    return this.http.get<any>(`${this.host}/admin/confirm/${code}`, this.config);
  }

  reset(val: any): Observable<any> {
    return this.http.post<any>(`${this.host}/admin/reset`, val, this.config);
  }

  forgot(email: string): Observable<any> {
    return this.http.post<any>(`${this.host}/admin/forgot`, {email}, this.config);
  }

  checkEmail(email: string): Observable<any> {
    return this.http.post<any>(`${this.host}/admin/email`, {email}, this.config);
  }

  checkInsCode(code: string): Observable<any> {
    return this.http.get<any>(`${this.host}/admin/inscode/${code}`, this.config);
  }

  getUserByResetCode(code: string): Observable<any> {
    return this.http.get<User>(`${this.host}/admin/user/reset/${code}`, this.config);
  }

  post(path: string, body: any = {}): Observable<any> {
    return this.http.post<any>(`${this.host}${path}`, body, this.config);
  }

  getMyProfile(): Profile {
    return this.profile;
  }

  getUserProfile(id: string | number): Observable<any> {
    return this.http.get<Profile>(`${this.host}/admin/profile/${id}`, this.config);
  }

  getProfile(id: string | number): void {
    this.getUserProfile(id)
      .subscribe((p: Profile) => {this.profile = p;}, err => {console.log(err.error.message)});
  }

  editProfile(data: any): Observable<any> {
    return this.http.put<any>(`${this.host}/admin/profile/edit`, data, this.config);
  }

  editAddress(data: any): Observable<any> {
    return this.http.post<any>(`${this.host}/admin/profile/address`, data, this.config);
  }

  getFiles(id: string | number): Observable<any> {
    return this.http.get<any>(`${this.host}/admin/files/instructor/${id}`, this.config);
  }

  updateFiles(data: any[]): Observable<any> {
    return this.http.put<any>(`${this.host}/admin/files/upload`, data, this.config);
  }

  uploadFiles(data: any[]): Observable<any> {
    return this.http.post<any>(`${this.host}/admin/files/upload`, data, this.config);
  }

  setSeason(season: string): void {
    this.scheduleReset.next(season);
  }

  clean(obj: any): any {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        obj[propName] = '';
      }
    }
    return obj;
  }

  downloadFile(fileData: any): void {
    const {Name: name, hash} = fileData;
    const fileUrl = `${this.host}/material/${hash}`;
    this.http.get(fileUrl, {responseType: 'blob' as 'json'}).subscribe(
      (response: any) => {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        if (name) {
          downloadLink.setAttribute('download', name);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.parentNode.removeChild(downloadLink);
        }
      });
  }

}
