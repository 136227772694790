import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

// import { AuthGuardService } from './api/authGuard';

// Import custom login component
import {LoginComponent} from './pages/login/login.component';
import {RegisterComponent} from './pages/register/register.component';
import {ConfirmComponent} from './pages/confirm/confirm.component';
import {ForgotComponent} from './pages/forgot/forgot.component';
import {ResetComponent} from './pages/reset/reset.component';

export const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.module')
      .then(m => m.AdminModule),
  },
  {
    path: 'instructor',
    loadChildren: () => import('./pages/instructor/ins.module')
      .then(m => m.InsModule),
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'confirm/:code',
    component: ConfirmComponent,
  },
  {
    path: 'reset/:code',
    component: ForgotComponent,
  },
  {
    path: 'forgot',
    component: ResetComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: '**', redirectTo: 'login'},
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
