<nb-layout>
  <nb-layout-column>
    <nb-auth-block>
      <div class="row login-container">
        <nb-card class="login-card confirm">
          <nb-card-header>
            <div class="logo">
              <img src="../../assets/images/spark/tshirtlogoblue.png">
              <p class="sub-title">Complete Registration</p>
              <ng-container *ngIf="registrationMessage">
                <p class="sub-title">
                {{ registrationMessage }} 
                </p>
                <div><a class="text-link" routerLink="../login">Login</a></div>
            </ng-container>
            </div>
          </nb-card-header>
        </nb-card>
      </div>
    </nb-auth-block>
  </nb-layout-column>
</nb-layout>
