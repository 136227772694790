import {Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, QueryList} from '@angular/core';
import {NbThemeService} from '@nebular/theme';
import {NgxSpinnerService} from 'ngx-spinner';
import {Api} from '../../api/apiModule';
import {Subscription, Observable} from 'rxjs';
import {AlertService} from '@shared/alert/alert.service';
import {FormGroup, FormControl, Validators, AbstractControl, ValidationErrors} from '@angular/forms';
import {environment} from 'environments/environment';
import {NbPopoverComponent} from '@nebular/theme';
import {timer} from 'rxjs';
import {Router} from '@angular/router';
@Component({
  selector: 'ngx-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {

  @ViewChild(NbPopoverComponent) popovers: QueryList<NbPopoverComponent>;

  @ViewChild('recaptcha') recaptcha: any;
  registerForm: FormGroup;
  subs: Subscription[] = [];
  phonePlaceHolder: string;
  siteKey = environment.recaptchaKey;
  emailMessage: string;

  constructor(
    private api: Api,
    private themeService: NbThemeService,
    private alert: AlertService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {
    this.setupForm();
    // Load the page theme
    this.themeService.getJsTheme();
  }

  ngOnInit() {
    // Spinner show display
    // this.spinner.show();
  }

  ngOnDestroy() {
    // this.spinner.hide();
  }

  emailValidator = (control: AbstractControl): Observable<ValidationErrors | null> => {
    return this.api.checkEmail(control.value);
  }

  /* Change to arrow fn to get scope of component to use this */
  passwordValidator = (control: AbstractControl): ValidationErrors | null => {
    const matched = this.registerForm.get('confirmPassword').value === control.value;
    return matched ? null : {match: false};
  }

  confirmValidator = (control: AbstractControl): ValidationErrors | null => {
    const matched = this.registerForm.get('password').value === control.value;
    return matched ? null : {match: false};
  }

  setupForm(): void {

    /* Seperate form into grpups for each step */
    this.registerForm = new FormGroup({
      firstName: new FormControl(null, [Validators.minLength(2), Validators.required]),
      lastName: new FormControl(null, [Validators.minLength(2), Validators.required]),
      email: new FormControl(null, {validators: [Validators.email, Validators.required], updateOn: 'blur'}),
      password: new FormControl(),
      confirmPassword: new FormControl(),
      g_recaptcha_response: new FormControl(null, Validators.required),
    });

    /* Since our custom validator references to the form we need it to be defined before setting */
    const passwordControl = this.registerForm.get('password');
    const confirmControl = this.registerForm.get('confirmPassword');
    const emailControl = this.registerForm.get('email');
    passwordControl.setValidators([Validators.minLength(8), Validators.required,
    Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/), this.passwordValidator]);
    confirmControl.setValidators([Validators.minLength(8), Validators.required, this.confirmValidator]);
    emailControl.setAsyncValidators([this.emailValidator]); // get directly from api
    // codeControl.setAsyncValidators([this.codeValidator]); // get directly from api

    this.subs.push(emailControl.statusChanges.subscribe(() => {
      this.emailExists();
    }));
    this.subs.push(confirmControl.valueChanges.subscribe(() => {
      passwordControl.updateValueAndValidity({onlySelf: true, emitEvent: true});
    }));

    this.subs.push(this.registerForm.valueChanges.subscribe(v => {}));

  }

  emailExists(): void {
    const emailErrors = this.registerForm?.get('email').errors;
    this.emailMessage = emailErrors?.message || null;
    this.cdr.detectChanges();
  }

  resolved(captchaResponse: string): void {
    const control = this.registerForm.get('g_recaptcha_response');
    control.patchValue(captchaResponse);
    control.updateValueAndValidity({onlySelf: true, emitEvent: true});
  }

  register(): void {
    const {firstName, lastName, email, password} = this.registerForm.value;
    this.spinner.show();
    this.api.register({firstName, lastName, email, password}).subscribe((res: any) => {
      this.spinner.hide();
      this.alert.good('Success');
      this.registerForm.reset();
      this.recaptcha.reset();
      timer(2000).subscribe(() => {
        this.router.navigate(['/login']);
      });
    }, (err: any) => {
      this.alert.bad(err.error.message);
      this.spinner.hide();
    });
  }

  loadData(): void {
    this.registerForm.patchValue({
      firstName: 'Ollie',
      lastName: 'Tinsley',
      email: 'camelcase78@gmail.com',
      password: '$enCryption7',
      confirmPassword: '$enCryption7',
    });
    this.registerForm.markAsDirty();
  }

  states = [
    {value: 'AL', description: 'Alabama'},
    {value: 'AK', description: 'Alaska'},
    {value: 'AZ', description: 'Arizona'},
    {value: 'AR', description: 'Arkansas'},
    {value: 'CA', description: 'California'},
    {value: 'CO', description: 'Colorado'},
    {value: 'CT', description: 'Connecticut'},
    {value: 'DE', description: 'Delaware'},
    {value: 'DC', description: 'District Of Columbia'},
    {value: 'FL', description: 'Florida'},
    {value: 'GA', description: 'Georgia'},
    {value: 'HI', description: 'Hawaii'},
    {value: 'ID', description: 'Idaho'},
    {value: 'IL', description: 'Illinois'},
    {value: 'IN', description: 'Indiana'},
    {value: 'IA', description: 'Iowa'},
    {value: 'KS', description: 'Kansas'},
    {value: 'KY', description: 'Kentucky'},
    {value: 'LA', description: 'Louisiana'},
    {value: 'ME', description: 'Maine'},
    {value: 'MD', description: 'Maryland'},
    {value: 'MA', description: 'Massachusetts'},
    {value: 'MI', description: 'Michigan'},
    {value: 'MN', description: 'Minnesota'},
    {value: 'MS', description: 'Mississippi'},
    {value: 'MO', description: 'Missouri'},
    {value: 'MT', description: 'Montana'},
    {value: 'NE', description: 'Nebraska'},
    {value: 'NV', description: 'Nevada'},
    {value: 'NH', description: 'New Hampshire'},
    {value: 'NJ', description: 'New Jersey'},
    {value: 'NM', description: 'New Mexico'},
    {value: 'NY', description: 'New York'},
    {value: 'NC', description: 'North Carolina'},
    {value: 'ND', description: 'North Dakota'},
    {value: 'OH', description: 'Ohio'},
    {value: 'OK', description: 'Oklahoma'},
    {value: 'OR', description: 'Oregon'},
    {value: 'PA', description: 'Pennsylvania'},
    {value: 'RI', description: 'Rhode Island'},
    {value: 'SC', description: 'South Carolina'},
    {value: 'SD', description: 'South Dakota'},
    {value: 'TN', description: 'Tennessee'},
    {value: 'TX', description: 'Texas'},
    {value: 'UT', description: 'Utah'},
    {value: 'VT', description: 'Vermont'},
    {value: 'VA', description: 'Virginia'},
    {value: 'WA', description: 'Washington'},
    {value: 'WV', description: 'West Virginia'},
    {value: 'WI', description: 'Wisconsin'},
    {value: 'WY', description: 'Wyoming'}
  ];
}
