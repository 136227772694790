import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {NbThemeService} from '@nebular/theme';
import {NgxSpinnerService} from 'ngx-spinner';
import {Api} from '../../api/apiModule';
import {Subscription} from 'rxjs';
import {AlertService} from '@shared/alert/alert.service';
import {FormGroup, FormControl, Validators} from '@angular/forms';
@Component({
  selector: 'spark-login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html',
})

export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  subs: Subscription[] = [];

  constructor(
    private router: Router,
    private api: Api,
    private themeService: NbThemeService,
    private alert: AlertService,
    private spinner: NgxSpinnerService,
  ) {
    this.setupForm();
    // Load the page theme
    this.themeService.getJsTheme();
  }

  ngOnInit() {
    // Spinner show display
    this.spinner.show();
    // Check to see if the user is logged in
    this.verifyAuth();
  }

  ngOnDestroy() {
    this.spinner.hide();
  }

  setupForm(): void {
    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required]),
    });
  }

  /**
   * Make sure that a connecting client is not already logged in,
   * and redirect them to the appropriate page if they are.
   */
  private verifyAuth(): void {
    // this.spinner.show();
    const auth = localStorage.getItem('auth');
    if (auth) {
      const usr = JSON.parse(localStorage.getItem('usr'));
      const {type} = usr;
      if (type === 'Admin') { // Send admins to admin stuff
        this.router.navigate(['/admin']);
      } else { // Send instructors to instructors stuff
        this.router.navigate(['/instructor']);
      }
    } else { // Client is not authorized
      this.spinner.hide();
    }
    // this.api.isAuthenticated().subscribe((res) => {
    //   console.log(res);
    //   const auth
    //   const {auth} = res;
    //   if (auth) { // Client is signed in
    //     if (type === 'Admin') { // Send admins to admin stuff
    //       this.router.navigate(['/admin']);
    //     } else { // Send instructors to instructors stuff
    //       this.router.navigate(['/instructor']);
    //     }
    //   } else { // Client is not authorized
    //     this.spinner.hide();
    //   }
    // });
  }

  /**
   * Attempt to send a login request which should return a json object
   * indicating if the login was successfull, and if it was then it tries
   * to verify their authentication at /auth
   */
  login(): void {
    // Check if either email or password are blank
    if (this.loginForm.valid) {
      const {email, password} = this.loginForm.value;
      // Submit login request
      this.api.login(email, password).subscribe((value) => {
        // If request is successfull
        // console.log(value);
        // const {success, user, user: {type, userID}} = value;
        const {success, user, user: {type}} = value;
        if (success) {
          this.api.setUser(user);
          if (type === 'Admin') { // Send admins to admin stuff
            this.router.navigate(['/admin']);
          } else { // Send instructors to instructors stuff
            // this.api.getProfile(userID);
            this.router.navigate(['/instructor']);
          }
          // this.verifyAuth();
        } else {
          this.alert.bad('Incorrect email or password');
        }
      }, err => {
        this.alert.bad('Incorrect email or password');
      });
    }
    // TODO: else show errors
  }
}
