import {Component, OnInit, OnDestroy} from '@angular/core';
import {NbThemeService} from '@nebular/theme';
import {NgxSpinnerService} from 'ngx-spinner';
import {Api} from '../../api/apiModule';
import {Subscription} from 'rxjs';
import {FormGroup, FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'ngx-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit, OnDestroy {

  resetForm: FormGroup;
  subs: Subscription[] = [];
  registrationMessage: string;

  constructor(
    private api: Api,
    private themeService: NbThemeService,
    private spinner: NgxSpinnerService,
  ) {
    // Load the page theme
    this.themeService.getJsTheme();
  }

  ngOnInit() {
    this.resetForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  ngOnDestroy() {}

  register(): void {
    const {email} = this.resetForm.value;
    this.spinner.show();
    this.api.forgot(email).subscribe((res: any) => {
      this.resetForm.reset();
      this.spinner.hide();
      this.registrationMessage = res.message;
    }, (err: any) => {
      this.registrationMessage = err.error.message;
      this.spinner.hide();
    });
  }
}
