
<nb-layout>
  <nb-layout-column>
    <nb-auth-block>
      <div class="row login-container">
        <nb-card class="login-card confirm">
          <nb-card-header>
            <div class="logo">
              <img src="../../assets/images/spark/tshirtlogoblue.png">
              <p class="sub-title">Forgot Password</p>
            </div>
          </nb-card-header>
          <nb-card-body>

            <form [formGroup]="resetForm"  (submit)="register()" aria-labelledby="title">

              <div class="form-control-group">
                <label class="label" for="input-password">Email:</label>
                <input
                  nbInput
                  formControlName="email"
                  fullWidth
                  shape="semi-round"
                  name="email"
                  type="text"
                  id="input-email"
                  placeholder="Registered email address"
                  fieldSize="large"
                  tabindex="1"
                  required="true">
              </div>
              
              <button
                nbButton
                fullWidth
                status="primary"
                tabindex="6"
                size="large"
                [disabled]="!resetForm.valid">
                Submit
              </button>

              <section *ngIf=" registrationMessage" class="another-action" aria-label="Reset">
                {{ registrationMessage }} 
              </section>

              <section class="another-action" aria-label="Reset">
                <a class="text-link" routerLink="../login">Login</a>
              </section>

            </form>

          </nb-card-body>
        </nb-card>

      </div>
    </nb-auth-block>
  </nb-layout-column>
</nb-layout>
