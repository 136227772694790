import {Component, OnInit, OnDestroy} from '@angular/core';
import {NbThemeService} from '@nebular/theme';
import {NgxSpinnerService} from 'ngx-spinner';
import {Api} from '../../api/apiModule';
import {Subscription} from 'rxjs';
import {FormGroup, FormControl, Validators, AbstractControl, ValidationErrors} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {timer} from 'rxjs';
@Component({
  selector: 'ngx-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit, OnDestroy {

  resetForm: FormGroup;
  subs: Subscription[] = [];
  phonePlaceHolder: string;
  registrationMessage: string;
  resetCode: string;

  constructor(
    private api: Api,
    private themeService: NbThemeService,
    private spinner: NgxSpinnerService,
    private ar: ActivatedRoute,
    private router: Router,
  ) {
    // Load the page theme
    this.themeService.getJsTheme();
  }

  ngOnInit() {
    this.spinner.show();
    this.ar.params.subscribe((params: any) => {
      const {code} = params;
      if (!code) {
        this.registrationMessage = 'Invalid Reset Code, please use the link from the confirmation email';
        this.spinner.hide();
      } else {
        this.resetCode = code;
        this.setupForm();
        this.spinner.hide();
      }
    });
  }

  ngOnDestroy() {
    this.subs.forEach((s: Subscription) => s.unsubscribe());
  }

  /* Change to arrow fn to get scope of component to use this */
  passwordValidator = (control: AbstractControl): ValidationErrors | null => {
    const matched = this.resetForm.get('confirmPassword').value === control.value;
    return matched ? null : {match: false};
  }

  confirmValidator = (control: AbstractControl): ValidationErrors | null => {
    const matched = this.resetForm.get('password').value === control.value;
    return matched ? null : {match: false};
  }

  setupForm(): void {
    /* Seperate form into grpups for each step */
    this.resetForm = new FormGroup({
      password: new FormControl(),
      confirmPassword: new FormControl(),
    });

    /* Since our custom validator references the form we need it to be defined before setting */
    const passwordControl = this.resetForm.get('password');
    const confirmControl = this.resetForm.get('confirmPassword');
    passwordControl.setValidators([Validators.minLength(6), Validators.required,
    Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/), this.passwordValidator]);
    confirmControl.setValidators([Validators.minLength(6), Validators.required, this.confirmValidator]);

    this.subs.push(confirmControl.valueChanges.subscribe(() => {
      passwordControl.updateValueAndValidity({onlySelf: true, emitEvent: true});
    }));

  }

  resolved(captchaResponse: string): void {
    const control = this.resetForm.get('g_recaptcha_response');
    control.patchValue(captchaResponse);
    control.updateValueAndValidity({onlySelf: true, emitEvent: true});
  }

  register(): void {
    const {password} = this.resetForm.value;
    this.spinner.show();
    this.api.reset({code: this.resetCode, password}).subscribe((res: any) => {
      this.registrationMessage = `${res.message}`;
      this.resetForm.reset();
      this.resetForm.disable();
      this.spinner.hide();
      timer(5000).subscribe(() => {
        this.router.navigate(['/login']);
      });
    }, (err: any) => {
      this.registrationMessage = err.error?.message || `Something went wrong with the password reset`;
      this.spinner.hide();
    });
  }
}
