import {Injectable} from '@angular/core';
import {NbToastrService} from '@nebular/theme';

@Injectable()
export class AlertService {
  constructor(
    private toastrService: NbToastrService,
  ) {
    // Constructor
  }

  show(message: string, title: string = 'Alert', config?: any) {
    this.toastrService.show(
      message,
      title,
      config,
    );
  }

  good(message: string) {
    this.toastrService.show(
      message,
      'Success',
      {
        status: 'success',
      },
    );
  }

  bad(message: string) {
    this.toastrService.show(
      message,
      'Error',
      {
        status: 'danger',
      },
    );
  }
}
