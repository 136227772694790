
<nb-layout>
  <nb-layout-column>
    <nb-auth-block>
      <div class="row login-container">
        <nb-card class="login-card confirm">
          <nb-card-header>
            <div class="logo">
              <img src="../../assets/images/spark/tshirtlogoblue.png">
              <p class="sub-title">Forgot Password</p>
            </div>
          </nb-card-header>
          <nb-card-body>

            <form *ngIf="resetForm" [formGroup]="resetForm"  (submit)="register()" aria-labelledby="title">

              <div class="form-control-group">
                <label class="label" for="input-password">Password:</label>
                <input
                  nbInput
                  formControlName="password"
                  fullWidth
                  shape="semi-round"
                  name="password"
                  type="password"
                  id="input-password"
                  placeholder="Password"
                  fieldSize="large"
                  tabindex="4"
                  required="true"
                  [nbPopover]="passwordHelper"
                  nbPopoverPlacement="end"
                 >
                 <ng-template #passwordHelper>
                      <p>Password must contain:</p>
                      <ul>
                        <li>A lower case letter</li>
                        <li>An upper case letter</li>
                        <li>A number</li><li>At least 8 characters</li>
                      </ul>
                  </ng-template>
              </div>

              <div class="form-control-group">
                <label class="label" for="input-confirmPassword">Confirm Password:</label>
                <input
                  nbInput
                  formControlName="confirmPassword"
                  fullWidth
                  shape="semi-round"
                  name="confirmPassword"
                  type="password"
                  id="input-confirmPassword"
                  placeholder="Confirm Password"
                  fieldSize="large"
                  tabindex="5"
                  required="true">
              </div>
              
              <button
                nbButton
                fullWidth
                status="primary"
                tabindex="6"
                size="large"
                [disabled]="!resetForm.valid">
                Reset Password
              </button>

              <section *ngIf="registrationMessage" class="another-action" aria-label="Register">
                {{ registrationMessage }}
              </section>

              <section class="another-action" aria-label="Register">
                <a class="text-link" routerLink="['/login']">Login</a>
              </section>

            </form>

          </nb-card-body>
        </nb-card>

      </div>
    </nb-auth-block>
  </nb-layout-column>
</nb-layout>
