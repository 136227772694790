<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <img src="../../assets/images/spark/tshirtlogoblue.png" height="60" width="150">
    </a>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action icon="settings-outline" [nbPopover]="settingsRef"></nb-action>
    <ng-template #settingsRef>
      <nb-menu [items]="items" (click)="action($event)"></nb-menu>
    </ng-template>
  </nb-actions>
</div>
