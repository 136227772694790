
<nb-layout>
  <nb-layout-column>
    <nb-auth-block>
      <div class="row login-container">
        <nb-card class="login-card">
          <nb-card-header>
            <div class="logo">
              <img src="../../assets/images/spark/tshirtlogoblue.png">
              <p class="sub-title">Hello! Log in with your email.</p>
            </div>
          </nb-card-header>
          <nb-card-body>

            <form [formGroup]="loginForm"  (submit)="login()" aria-labelledby="title">

              <div class="form-control-group">
                <label class="label" for="input-email">Email address:</label>
                <input
                  nbInput
                  formControlName="email"
                  fullWidth
                  shape="semi-round"
                  name="email"
                  id="input-email"
                  pattern=".+@.+\..+"
                  placeholder="Email address"
                  fieldSize="large"
                  autofocus
                  tabindex="1"
                  required="true">
               </div>

              <div class="form-control-group">
                <span class="label-with-link">
                  <label class="label" for="input-password">Password:</label>
                  <a class="forgot-password caption-2" routerLink="../forgot">Forgot Password?</a>
                </span>
                <input
                  nbInput
                  formControlName="password"
                  fullWidth
                  shape="semi-round"
                  name="password"
                  type="password"
                  id="input-password"
                  placeholder="Password"
                  fieldSize="large"
                  tabindex="2"
                  required="true">
              </div>

              <button
                nbButton
                fullWidth
                status="primary"
                tabindex="3"
                size="large"
                [disabled]="!loginForm.valid">
                Log In
              </button>

              <section class="another-action" aria-label="Register">
                New Instructor? <a class="text-link" routerLink="../register">Register</a>
              </section>

            </form>

          </nb-card-body>
        </nb-card>

      </div>
    </nb-auth-block>
  </nb-layout-column>
</nb-layout>
